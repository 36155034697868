const header = document.querySelector('.header');
const breakpointLg = window.matchMedia('(min-width: 1024px)');
const headerMenu = document.querySelector('.header__wrapper');
const scrollUp = document.querySelector('.scroll-up');
const progressBar = document.querySelector('.progress-bar');

const resetAcc = () => {
  const activeEls = header.querySelectorAll('.accordion');
  const openAccBtn = header.querySelector('.accordion__btn.active');
  const openAcc = header.querySelector('.accordion__content.active');

  if (openAccBtn && openAcc) {
    activeEls.forEach((activeEl)=> {
      activeEl.children[0].classList.remove('active');
      activeEl.children[1].classList.remove('active');
      activeEl.children[1].removeAttribute('style');
    });
  }
}

const resetMenu = () => {
  const openEls = header.querySelectorAll('.open');
  const clickedEls = header.querySelectorAll('.clicked');
  openEls.forEach((openEl)=> {
    openEl.classList.remove('open');
  });

  clickedEls.forEach((clickedEl)=> {
    clickedEl.classList.remove('clicked');
  });

  resetAcc();
}


const initMenuActions = () => {
  if (header) {
    let bottomBlock = header.querySelector('.main-nav__bottom');
    const closeBtn = header.querySelector('.header__close');
    const submenues = header.querySelectorAll('.js-menu');

    closeBtn.addEventListener('click', () => {
      resetMenu();
      header.classList.remove('open');

      window.enableBodyScroll(headerMenu);

      if (scrollUp) {
        scrollUp.removeAttribute('style');
      }

      if (progressBar) {
        progressBar.removeAttribute('style');
      }

    });

    submenues.forEach((submenu) => {
      submenu.addEventListener('click', (e) => {
        if (!header.classList.contains('open')) {
          e.preventDefault();

          header.classList.add('open');

          let submenuContent = submenu.querySelector('.js-submenu-content');

          if (breakpointLg.matches) {
            submenuContent.classList.add('clicked');
            submenu.classList.add('clicked');
            // header.classList.add('header--black');
          } else {
            submenuContent.classList.add('open');
            submenu.classList.add('open');
          }


          window.disableBodyScroll(headerMenu, {
            reserveScrollBarGap: true,
          });

          if (scrollUp) {
            scrollUp.style.display = 'none';
          }

          if (progressBar) {
            progressBar.style.display = 'none';
          }

          if (submenu.classList.contains('show-bottom') && breakpointLg.matches) {
            bottomBlock.classList.add('show');
          } else {
            bottomBlock.classList.remove('show');
          }
        }
      });
    });
  }
}


export {initMenuActions};
