const inputWrappers = document.querySelectorAll('.custom-input');

const initInputWrappers = () => {
  inputWrappers.forEach((el) => {
    const input = el.querySelector('input');
    const requiredInput = el.querySelector('input:required');

    el.addEventListener('input', () => {
      if (input.value) {
        el.classList.add('custom-input--has-value');
      } else {
        el.classList.remove('custom-input--has-value');
      }
    });
  });
};

export {initInputWrappers};
