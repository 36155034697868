const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  var difference = to - element.scrollTop;
  var perTick = difference / duration * 10;

  setTimeout(function() {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
}

const ESC_KEYCODE = 27;
const progressBar = document.querySelector('.progress-bar');
const header = document.querySelector('.header');
const footer = document.querySelector('.footer');
const page = document.querySelector('.page');
const screenCounter = 3;
const PROGRESS_LENGHT_MAX = 282; //2*3.14*45 = 282

const isEscClick = (evt, progressBarBtn) => {
  if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === ESC_KEYCODE) {
    closeMenu(progressBarBtn);
  }
};

const openMenu = (progressBarBtn) => {
  progressBarBtn.ariaPressed = 'true';
  progressBarBtn.ariaLabel = 'Открыть список задач';
  progressBar.classList.add('active');
  //progressBarWrap.style.maxHeight = progressBarList.offsetHeight + 'px';
};

const closeMenu = (progressBarBtn) => {
  progressBarBtn.ariaPressed = 'false';
  progressBarBtn.ariaLabel = 'Закрыть список задач';

  setTimeout(() => {
    progressBar.classList.remove('active');
  }, 100);
};


const updateProgress = () => {
  if (progressBar && header && page) {
    let headerHeight = (header)? header.offsetHeight : 0;
    let footerHeight = (footer)? footer.offsetHeight : 0;
    let scrollPagePercent = Math.round((window.scrollY + window.innerHeight) * 100 / (headerHeight + footerHeight  + page.offsetHeight));
    const progressBarBtn = progressBar.querySelector('.progress-bar__btn');
    const progressSvg = progressBarBtn.querySelector('svg:nth-child(3) circle');
    const dashLenght = (scrollPagePercent) / 100 * PROGRESS_LENGHT_MAX ;
    const gapLenght = PROGRESS_LENGHT_MAX - dashLenght;


    progressSvg.style.strokeDasharray = (dashLenght + " " + gapLenght);
  }
}

const showProgressBar = () => {
  if (window.scrollY > window.innerHeight * (screenCounter - 1)) {
    setTimeout(() => {
      progressBar.classList.add('show');
    }, 300);
  } else {
    setTimeout(() => {
      progressBar.classList.remove('show');
    }, 300);
  }

  setTimeout(() => {
    updateProgress();
  }, 400);
};

const initProgressBarAction = () => {
  if (progressBar) {
    const progressBarBtn = progressBar.querySelector('.progress-bar__btn');
    const progressBarList = progressBar.querySelector('.progress-bar__list');

    progressBarBtn.addEventListener('click', () => {
      if (progressBarBtn.ariaPressed === 'true') {
        closeMenu(progressBarBtn);
      } else {
        openMenu(progressBarBtn);
      }
    });

    progressBarList.addEventListener('click', () => closeMenu (progressBarBtn));
    document.addEventListener('keydown', (evt) => {
      isEscClick(evt, progressBarBtn);
    });
  }
};

const initProgressBar = () => {
  if (progressBar) {
    window.addEventListener('scroll', showProgressBar);
  }

  const anchorLinks = document.querySelectorAll('.anchor-item');
  if (anchorLinks && anchorLinks.length > 0) {
    anchorLinks.forEach((anchor) => {
      anchor.addEventListener('click', (evt) => {
        evt.preventDefault();
        const target = anchor.hash;
        const targetElement = document.querySelector(target);
        if (targetElement) {
          scroll({
            top: targetElement.offsetTop - 100,
            behavior: "smooth"
          });
        }
      });
    });
  }
};

export {initProgressBar, initProgressBarAction};
