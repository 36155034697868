const header = document.querySelector('.header');
const burgerBtn = document.querySelector('.header__toggle');
const breakpointLg = window.matchMedia('(max-width:1023px)');
const breakpointMd = window.matchMedia('(max-width:768px)');
const headerMenu = document.querySelector('.header__wrapper');
const mainNav = document.querySelector('.main-nav__list');
const scrollUp = document.querySelector('.scroll-up');
const progressBar = document.querySelector('.progress-bar');

const openMenu = () => {
  burgerBtn.ariaPressed = 'true';
  window.disableBodyScroll(headerMenu);
  header.classList.add('header--opened');
  if (scrollUp) {
    scrollUp.style.display = 'none';
  }

  if (progressBar) {
    progressBar.style.display = 'none';
  }
};

const closeMenu = () => {
  const openAccBtn = header.querySelector('.accordion__btn.active');
  const openAcc = header.querySelector('.accordion__content.active');
  const headerWrapper = header.querySelector('.main-nav__wrapper');
  const searchForm = header.querySelector('form');
  const searchFormBlock = header.querySelector('.search-form');
  const searchBtn = header.querySelector('.search-btn');
  if (openAccBtn && openAcc) {
    openAccBtn.classList.remove('active');
    openAcc.classList.remove('active');
  }
  burgerBtn.ariaPressed = 'false';
  const opened = header.querySelectorAll('.open');
  opened.forEach((e) => {
    e.classList.remove('open');
  });

  searchBtn.ariaPressed = 'false';
  headerWrapper.classList.remove('search-open');
  searchFormBlock.classList.remove('search-open');
  searchForm.reset();

  header.classList.remove('open');
  header.classList.remove('header--opened');

  if (scrollUp) {
    scrollUp.removeAttribute('style');
  }

  if (progressBar) {
    progressBar.removeAttribute('style');
  }
  
  window.enableBodyScroll(headerMenu);
};

const resetAcc = () => {
  const activeEls = header.querySelectorAll('.accordion');
  const openAccBtn = header.querySelector('.accordion__btn.active');
  const openAcc = header.querySelector('.accordion__content.active');

  if (openAccBtn && openAcc) {
    activeEls.forEach((activeEl)=> {
      activeEl.children[0].classList.remove('active');
      activeEl.children[1].classList.remove('active');
      activeEl.children[1].removeAttribute('style');
    });
  }
}

const resetMenu = () => {
  const openEls = header.querySelectorAll('.open');
  const clickedEls = header.querySelectorAll('.clicked');

  openEls.forEach((openEl)=> {
    openEl.classList.remove('open');
  });

  clickedEls.forEach((clickedEl)=> {
    clickedEl.classList.remove('clicked');
  });

  resetAcc();
}

const closeMenuOnResize = () => {
  closeMenu();
  resetMenu();
  header.classList.remove('header--black');
};

const initBurgerAction = () => {
  if (burgerBtn) {
    breakpointLg.addListener(closeMenuOnResize);
    breakpointMd.addListener(closeMenuOnResize);
    burgerBtn.addEventListener('click', () => {
      if (burgerBtn.ariaPressed === 'true') {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }
};

export {initBurgerAction};
