import axios from "axios";

const initFeedbackForm = () => {
  const feedbackForm = document.querySelector('.js-feedback-form');

  if (feedbackForm) {
    feedbackForm.addEventListener('validated-submit',function(e){
      const data = new FormData(feedbackForm);
      const action = feedbackForm.action;
      const method = feedbackForm.method;

      axios({
        method: method,
        url: action,
        data: data,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          "Content-Type": "multipart/form-data"

        }
      }).then((response) => {
        const data = response.data;
        if (data.success == 'ok') {
          feedbackForm.innerHTML = '<p class="feedback-success" style="font-size: 22px; color: green;">' + data.message + '</p>'
        }
      });
    });
  }
};

export {initFeedbackForm};
