const feedbackBlocks = document.querySelectorAll('.slider--feedback');

const setupFeedbackSlider = (e) => {
  let feedbackSlider = e.querySelector('.swiper-container');
  if (feedbackSlider) {
    let feedbackNavigation = feedbackSlider.lastElementChild;
    let feedbackSwiper = new Swiper(feedbackSlider, {
      slidesPerView: 1,
      effect: 'fade',
      loop: true,
      speed: 100,
      autoHeight: true,

      fadeEffect: {
        crossFade: true
      },

      navigation: {
        nextEl: feedbackNavigation.querySelector('.swiper-button-next'),
        prevEl: feedbackNavigation.querySelector('.swiper-button-prev'),
      },

      pagination: {
        el: feedbackNavigation.querySelector('.swiper-pagination'),
        type: 'fraction',
      },
    });

    window.addEventListener('load', function () {
      feedbackSwiper.update();
    });

    window.addEventListener('resize', function () {
      feedbackSwiper.update();
    });
  }
};

const initFeedbackSlider = () => {
  if (feedbackBlocks.length) {
    feedbackBlocks.forEach((e) => {
      setupFeedbackSlider(e);
    });
  }
};

export {initFeedbackSlider};
