const gmapBlock = document.querySelector('#map2');

//let map;

const initMap = () => {
  if  (gmapBlock) {
    // The location of SolutionsKiev
  const SolutionsKiev = { lat: 50.445841, lng: 30.42988 };
  // The map, centered at SolutionsKiev
  const map = new google.maps.Map(gmapBlock, {
    zoom: 15,
    center: SolutionsKiev,
  });
  // The marker, positioned at SolutionsKiev
  const marker = new google.maps.Marker({
    position: SolutionsKiev,
    map: map,
  });
  }
}



/*const initMap = () => {
  console.log('google map');
  const SolutionsKiev = { lat: 50.445841, lng: 30.42988 };
  let myMap =  new google.maps.Map(gmapBlock, {
    center: SolutionsKiev,
    zoom: 15,
  });

  // The marker, positioned at SolutionsKiev
  const marker = new google.maps.Marker({
    position: SolutionsKiev,
    map: myMap,
  });
}
*/
export {initMap};
