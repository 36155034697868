const initFormValidate = () => {
  const forms = document.querySelectorAll(' form.js-form-validate');

  if (!forms.length) {
    return;
  }

  const checkInput = (item) => {
    let inputType = item.getAttribute('type');
    let isValid;

    if (inputType === 'text') {
      isValid = checkName(item);
    }

    if (inputType === 'tel') {
      isValid = checkPhone(item);
    }

    if (inputType === 'email') {
      isValid = checkEmail(item);
    }

    if (inputType === 'checkbox') {
      isValid = checkCkeckbox(item);
    }

    return isValid;
  };

  const showError = (input) => {
    const inputWrap = input.closest('.js-field');
    inputWrap.classList.remove('success');
    inputWrap.classList.add('error');

    const errorMessage = inputWrap.querySelector('.custom-input__error-message');
    if (errorMessage) {
      errorMessage.classList.add('custom-input__error-message--shown');
      errorMessage.style.maxHeight = errorMessage.scrollHeight + 'px';
    }
  };

  const hideErrorMessage = (parent) => {
    const errorMessage = parent.querySelector('.custom-input__error-message');
    if (errorMessage) {
      errorMessage.classList.remove('custom-input__error-message--shown');
      errorMessage.style.maxHeight = '0';
    }
  };

  const hideError = (input) => {
    const inputWrap = input.closest('.js-field');
    inputWrap.classList.remove('error');
    inputWrap.classList.add('success');

    hideErrorMessage(inputWrap);
  };

  const checkName = (input) => {
    let isValid;

    if (input.value && input.value !== ' ') {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  };

  const checkPhone = (input) => {
    const phonePattern = '^[0-9+()-]';
    const regExpPhone = new RegExp(phonePattern, '');
    //const minValue = Number(input.getAttribute('minlength'));
    const minValue = 7;
    let isValid;

    if (regExpPhone.test(input.value) && (input.value.length >= minValue)) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  };

  const checkEmail = (input) => {
    const emailPattern = '^([a-zа-я0-9_-]+.)*[a-zа-я0-9_-]+@[a-zа-я0-9_-]+\\.[a-zа-я]{2,}$';
    const regExpEmail = new RegExp(emailPattern, '');
    let isValid;

    if (regExpEmail.test(input.value)) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  };

  const checkCkeckbox = (input) => {
    let isValid;

    if (input.checked) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  };

  const showInputValid = (input) => {
    let inputType = input.getAttribute('type');

    if (inputType === 'text') {
      const parent = input.closest('.js-field');
      if (!parent.classList.contains('error')) {
        if (checkName(input)) {
          hideError(input);
        } else {
          input.closest('.js-field').classList.remove('success');
        }
      }
    }

    if (inputType === 'tel') {
      const parent = input.closest('.js-field');
      if (!parent.classList.contains('error')) {
        if (checkPhone(input)) {
          hideError(input);
        } else {
          input.closest('.js-field').classList.remove('success');
        }
      }
    }

    if (inputType === 'email') {
      const parent = input.closest('.js-field');
      if (!parent.classList.contains('error')) {
        if (checkEmail(input)) {
          hideError(input);
        } else {
          input.closest('.js-field').classList.remove('success');
        }
      }
    }
  };

  const submitForm = (form) => {
    const inputs = form.querySelectorAll('input[required]');
    form.noValidate = true;

    inputs.forEach((input) => {
      input.addEventListener('input', () => {
        showInputValid(input);

        if (input.closest('.js-field').classList.contains('error')) {
          if (checkInput(input)) {
            hideError(input);
          } else {
            showError(input);
          }
        }
      });
    });

    form.addEventListener('submit', (evt) => {
      evt.preventDefault();
      let invalidInputs = [];

      inputs.forEach((input) => {
        if (checkInput(input)) {
          hideError(input);
        } else {
          showError(input);
        }


        if (input.closest('.js-field').classList.contains('error')) {
          invalidInputs.push(input);
        }
      });

      if (invalidInputs.length) {
        if (invalidInputs[0].type !== 'checkbox') {
          invalidInputs[0].focus();
        }
      } else {
        form.dispatchEvent(new CustomEvent('validated-submit'));
      }
    });
  };

  forms.forEach((item) => {
    submitForm(item);
  });

};

export {initFormValidate};
