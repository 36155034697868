import axios from "axios";

const initBlog = () => {
  const jsItemContainer = document.querySelector('.js-items-container');

  if (jsItemContainer) {
    document.addEventListener('click',function(e){
      const target = e.target;
      const buttonLoadMore = target.closest('.js-load-more-btn');

      if (buttonLoadMore) {
        e.preventDefault();
        const endpoint = buttonLoadMore.dataset.nextPageUrl;

        axios({
          method: 'get',
          url: endpoint,
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          }
        }).then((response) => {
          let tmpWrap = document.createElement('div');
          tmpWrap.innerHTML= response.data;
          const moreItems = tmpWrap.querySelector('.blog-list__item');
          if (moreItems) {
            jsItemContainer.append(moreItems);
          }

          const moreButton = tmpWrap.querySelector('.js-load-more-btn');
          if (moreButton) {
            buttonLoadMore.dataset.nextPageUrl = moreButton.dataset.nextPageUrl;
          } else {
            buttonLoadMore.remove();
          }
        });
      }
    });
  }
};

export {initBlog};
