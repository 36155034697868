
const ACTIVE_TAB = "0";
const selectTabs = document.querySelectorAll('.select__tab');
const breakpointLg = window.matchMedia('(max-width:1023px)');


const removeItemActiveClass = (arr) => {
  arr.forEach((el) => {
    el.classList.add('no-opacity');
    setTimeout(() => {
      el.classList.add('hide');
    }, 300);
  });
};

const openActiveTab = (nodes, index) => {
  const tabArray = Array.from(nodes);
  setTimeout(() => {
    tabArray[index].classList.remove('no-opacity');
  }, 300);
  setTimeout(() => {
    tabArray[index].classList.remove('hide');
  }, 300);
}

const openAllTabs = (nodes) => {
  const tabArray = Array.from(nodes);
  tabArray.forEach((tabItem) => {
    if (tabItem.classList.contains('no-opacity')) {
      tabItem.classList.remove('no-opacity');
    }

    if (tabItem.classList.contains('hide')) {
      tabItem.classList.remove('hide');
    }
  })
}

const setInitialState = (nodes) => {
  const tabArray = Array.from(nodes);

  nodes.forEach((node) => {
    node.classList.add('no-opacity');
    node.classList.add('hide');
  });

  tabArray[ACTIVE_TAB].classList.remove('hide');
  tabArray[ACTIVE_TAB].classList.remove('no-opacity');
}

const checkWindowSize = () => {
  if (breakpointLg.matches) {
    selectTabs.forEach((selectTab) => setTimeout(() => {
      initTabAction(selectTab);
    }, 300)
    )
  } else {
    selectTabs.forEach((selectTab) => openAllTabs(selectTab.querySelectorAll('.select__tab-item')))
  }
}


const initTabAction = (selectTab) => {
  const inputSelectWrap = selectTab.querySelector('.custom-input--select');
  const selectTabItems = selectTab.querySelectorAll('.select__tab-item');
  const inputSelect = selectTab.querySelector('.custom-input__wrapper input');

  if (!inputSelectWrap) {
    return;
  }

  setInitialState(selectTabItems);

  inputSelect.addEventListener('input', (e) => {
    e.preventDefault();
    removeItemActiveClass(selectTabItems);
    openActiveTab(selectTabItems, inputSelect.dataset.activeTab)
  })
}


const connectInputSelectTab = () => {
  if (selectTabs.length && breakpointLg.matches) {
    selectTabs.forEach((selectTab) => initTabAction(selectTab));
  }

  if (selectTabs.length) {
    window.addEventListener('resize', checkWindowSize);
  }
};

export {connectInputSelectTab};
