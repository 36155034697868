const modalLangForm = document.querySelector('.modal--lang form');

const initLang = () => {
  if (!modalLangForm) {
    return
  }

  const regionSelect = modalLangForm.querySelector('.modal-lang__input-wrap--region select');
  const langSelect = modalLangForm.querySelector('.modal-lang__input-wrap--lang select');
  const langBtnText = modalLangForm.querySelector('.modal-lang__input-wrap--lang .custom-select__text');
  const ukrLangSelect = modalLangForm.querySelector('.modal-lang__input-wrap--lang option[value= "ukr"]');
  const ukrLangItem = modalLangForm.querySelector('.modal-lang__input-wrap--lang .custom-select__item[data-select-value= "ukr"]');
  const ruLangItem = modalLangForm.querySelector('.modal-lang__input-wrap--lang .custom-select__item[data-select-value= "ru"]');

  const checkLang = () => {
    console.log(ukrLangItem);
    if (regionSelect.value === "Ukraine") {
      if (ukrLangItem.classList.contains('hidden')) {
        ukrLangItem.classList.remove('hidden');
      }

    } else {

      if (!ukrLangItem.classList.contains('hidden')) {
        ukrLangItem.classList.add('hidden');
      }

      if (langSelect.value === 'ukr') {
        const innerTextLastLang = ruLangItem.textContent
        ukrLangItem.setAttribute('aria-selected', 'false');
        ruLangItem.setAttribute('aria-selected', 'true');
        ukrLangSelect.selected = true;
        langBtnText.innerHTML = innerTextLastLang;
      }
    }
  }

  modalLangForm.addEventListener('change', () =>
    setTimeout( () => {
      checkLang();
    }, 100)
    )

}

export {initLang}
