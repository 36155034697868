const missionBlocks = document.querySelectorAll('.slider--mission');

const setupMissionSlider = (e) => {
  const missionSlider = e.querySelector('.swiper-container');
  if (missionSlider) {
    let missionSwiper = new Swiper(missionSlider, {
      slidesPerView: 1,

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });

    window.addEventListener('load', function () {
      missionSwiper.update();
      e.querySelector('.slider__progress').classList.add('active');
    });

    missionSwiper.on('slideChangeTransitionEnd', function () {
      e.querySelector('.slider__progress').classList.remove('active');
      setTimeout(() => {
        e.querySelector('.slider__progress').classList.add('active');
      }, 10);
    });
  }
};

const initMissionSlider = () => {
  if (missionBlocks.length) {
    missionBlocks.forEach((e) => {
      setupMissionSlider(e);
      const missionSlider = e.querySelector('.swiper-container');
      const swiper = missionSlider.swiper;
      const verticalCenter = window.innerHeight / 2;

      window.addEventListener('scroll', function () {
        const elBottom = e.getBoundingClientRect().bottom;
        const elTop = e.getBoundingClientRect().top;
        if (elTop < window.innerHeight && elBottom > 0) {
          swiper.autoplay.start();
          e.querySelector('.slider__progress').classList.add('active');
        } else {
          swiper.autoplay.stop();
          e.querySelector('.slider__progress').classList.remove('active');
        }
      })
    });
  }
};

export {initMissionSlider};
