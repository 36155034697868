import Translator from '../services/translator';
const objectModalMarkup = ({logo, bg, name, href, description}) => {
  return (
    `<div class="modal-partner__head">
      <div class="modal-partner__bg-image">
        <img src="${bg.src}" width="768" height="372" srcset="${bg.scrcet} 2x" alt="Partner showcase">
      </div>

      <div class="modal-partner__logo">
        <img src="${logo.src}" width="768" height="372" srcset="${logo.scrcet} 2x" alt="Partner logo">
      </div>
    </div>

    <div class="modal-partner__content">
      <div class="modal-partner__meta">
        <span class="modal-partner__name">${name}</span>
        <a class="modal-partner__link" href="${href}">${Translator.trans('main.company_page')}</a>
      </div>

      <div class="modal-partner__text-wrapper">
        <p class="modal-partner__text">${description}</p>
      </div>
    </div>
  `);
};

const objectModalTemplate = (res, block) => {
  block.insertAdjacentHTML('afterbegin', objectModalMarkup(res));
};

export {objectModalTemplate};
