import axios from "axios";

const header = document.querySelector('.header');
const breakpointLg = window.matchMedia('(max-width:1023px)');
const breakpointMd = window.matchMedia('(max-width:768px)');

const openMenu = (searchBtn, searchFormBlock, searchResult) => {
  searchBtn.ariaPressed = 'true';
  searchFormBlock.classList.add('search-open');
  if (searchBtn.closest('.header') && !breakpointLg.matches) {
    window.disableBodyScroll(searchResult, {
      reserveScrollBarGap: true,
    });
  }
};

const debounce = (fn, delay) => {
  var timer = null;
  return function () {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

const closeMenu = () => {
  const searchBlock = document.querySelectorAll('.search-block');
  if (searchBlock.length) {
    searchBlock.forEach((el) => {
      const searchBtn = el.querySelector('.search-btn');
      const openEls = el.querySelectorAll('.search-open');
      const searchResult = el.querySelector('.search-result');
      const searchForm = el.querySelector('form');
      searchBtn.ariaPressed = 'false';
      window.enableBodyScroll(searchResult);
      if (searchBtn.closest('.header')) {
        window.enableBodyScroll(searchResult);
      }

      if (openEls.length) {
        openEls.forEach((openEl) => {
          openEl.classList.remove('search-open');
        });
      }
      searchForm.reset();
      searchResult.classList.remove('open');
    });
  }

};

const setupSearch = (el) => {
  const searchBtn = el.querySelector('.search-btn');
  const searchFormBlock = el.querySelector('.search-form');
  const searchInput = el.querySelector('input');
  const searchResult = el.querySelector('.search-result');
  const searchResultError = el.querySelector('.search-result--error');
  const headerWrapper = header.querySelector('.main-nav__wrapper');

  searchInput.addEventListener('input', debounce(() => {
    const thisForm = searchInput.form;
    const action = thisForm.action;
    const method = thisForm.method;

    axios({
      method: method,
      url: action,
      params: {
        q: thisForm.q.value
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "Content-Type": "multipart/form-data"

      }
    }).then((response) => {
      const dataHtml = response.data;
      if (dataHtml) {
        searchResult.innerHTML = dataHtml;
        //searchResult.insertAdjacentHTML( 'beforeend', dataHtml);
        openMenu(searchBtn, searchFormBlock, searchResult);
        searchResult.classList.add('open');
        searchResultError.classList.remove('open');
        headerWrapper.classList.add('search-open');
      } else {
        searchResult.classList.remove('open');
        searchResultError.classList.add('open');
      }
    });
  }, 250));

  searchBtn.addEventListener('click', () => {
    if (!searchBtn.closest('.header')) {
      if (searchBtn.ariaPressed === 'true') {
        closeMenu();
      } else {
        searchInput.focus();
      }
    } else {
      if (searchBtn.ariaPressed === 'true') {
        closeMenu();
      } else {
        openMenu(searchBtn, searchFormBlock, searchResult);
        headerWrapper.classList.add('search-open');
      }
    }
  });

  document.addEventListener('click', (e) => {
    if (searchBtn.ariaPressed === 'true' && searchFormBlock.classList.contains('search-open')) {
      if (!e.target.closest('.search-result') && e.target.closest('.search-form')) {
        searchInput.focus();
      } else if (!e.target.closest('.search-result')) {
        closeMenu();
        searchResult.classList.remove('open');
      }
    }
  });
}

const closeSearchOnResize = () => {
  closeMenu();
}

const initSearchAction = () => {
  const searchBlock = document.querySelectorAll('.search-block');
  if (searchBlock.length) {
    searchBlock.forEach((el) => {
      setupSearch(el);
    });
  }

  breakpointLg.addListener(closeSearchOnResize);
  breakpointMd.addListener(closeSearchOnResize);
};

export {initSearchAction};
