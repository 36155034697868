const breakpointMd = window.matchMedia('(min-width: 768px)');
const breakpointLg = window.matchMedia('(min-width: 1024px)');
const header = document.querySelector('.header');
const headerMain = document.querySelector('.header--main');
const windowHeight = window.innerHeight;

let pageYPos;
let scrollDirection;
let pageYPos2 = 0;

let headerHeight;

window.addEventListener('load', function () {
  getHeaderHeight();
  getHeaderFixHeight();
});

window.addEventListener('resize', function () {
  getHeaderHeight();
  getHeaderFixHeight();
});

const getHeaderFixHeight = () => {
  if (breakpointLg.matches) {
    const headerFixed = document.querySelector('.header--fixed');
    if (headerFixed) {
      headerHeight = headerFixed.offsetHeight;
    }
  }
};

const getHeaderHeight = () => {
  if (breakpointLg.matches) {
    headerHeight = header.offsetHeight;
  }
};

const isHeaderFixed = () => {
  return header.classList.contains('header--fixed');
};

const addFixHeader = () => {
  header.classList.add('header--fixed');
  if (headerMain) {
    header.classList.remove('header--hero');
  }
};

const resetHeader = () => {
  header.classList.remove('header--fixed');
  header.classList.remove('header--shown');
  header.removeAttribute('style');

  if (headerMain) {
    header.classList.add('header--hero');
  }
};

const scrollHeader = () => {
  if (breakpointLg.matches) {
    scrollDirection = ((window.pageYOffset - pageYPos) > 0) ? 'down' : 'up';

    if (scrollDirection === 'down') {
      if (pageYPos2 >= 0) {
        pageYPos2 = 0;
      }

      pageYPos2 += (pageYPos - window.pageYOffset);

      if (Math.abs(pageYPos2) > headerHeight) {
        pageYPos2 = -headerHeight;
      }

    } else {
      if (pageYPos2 >= 0) {
        pageYPos2 = 0;
      } else {
      pageYPos2 += pageYPos - window.pageYOffset;
      }
    }

    pageYPos = window.pageYOffset;

    const defineHeaderAppearance = () => {
      if (headerMain) {
        if (pageYPos > windowHeight) {
          getHeaderFixHeight();
          if (scrollDirection === 'up') {
            if (pageYPos2 <= 0) {
              header.style.top = pageYPos2 + "px";
            } else {
              header.style.top = 0;
            }
          }

          if (scrollDirection === 'down') {
            if (pageYPos2 <= 0) {
              header.style.top = pageYPos2 + "px";
            } else {
              header.style.top = 0;
            }

            if (!isHeaderFixed()) {
              addFixHeader();
            }
          }
        }
      } else {
        if (pageYPos > headerHeight) {
          getHeaderHeight();
          if (pageYPos2 <= 0) {
            header.style.top = pageYPos2 + "px";
          } else {
            header.style.top = 0;
          }

          if (scrollDirection === 'down') {
            if (pageYPos2 <= 0) {
              header.style.top = pageYPos2 + "px";
            } else {
              header.style.top = 0;
            }


            if (!isHeaderFixed()) {
              addFixHeader();
            }
          }
        }
      }
    };

    if (headerMain) {
      if (pageYPos < windowHeight) {
        resetHeader();
      }
    } else {
      if (pageYPos < 1) {
        resetHeader();
      }
    }

    defineHeaderAppearance(headerHeight);
  }

};

const fixHeader = () => {
  if (!header) {
    return;
  }

  if (breakpointLg.matches && header) {
    pageYPos = window.pageYOffset;

    if (pageYPos > 0) {
      if (!isHeaderFixed()) {
        addFixHeader();
      }
    }

    window.addEventListener('scroll', scrollHeader);
  } else {
    resetHeader();
  }
};

window.addEventListener('resize', fixHeader);

export {fixHeader};
