const mainHero = document.querySelector('.main-hero');

const initImgChange = (index, imgItems) => {
  imgItems.forEach((imgItem) => {
    imgItem.classList.remove('active');
  });

  imgItems[index-1].classList.add('active');
}

const initLinkChange = (linkItems, imgItems) => {
  linkItems.forEach((linkItem) => {
    linkItem.addEventListener('mouseenter', () => {
      let index = Number(linkItem.dataset.link);
      linkItems.forEach((linkItem) => {
        linkItem.closest('.main-hero__link-item').classList.remove('active');
      });
      linkItem.closest('.main-hero__link-item').classList.add('active');

      initImgChange(index, imgItems);
    });
  });
}

const initHeroAction = () => {
  if (mainHero) {
    const imgItems = mainHero.querySelectorAll('.main-hero__img-item');
    const linkItems = mainHero.querySelectorAll('.main-hero__link');

    if (imgItems.length && linkItems.length) {
      initLinkChange(linkItems, imgItems);
    }
  }
}

export {initHeroAction};
