const initAccordionAction = (accordion) => {
  const btn = accordion.children[0];
  const contentPic = accordion.querySelector('.accordion__pic');
  const contentPics = document.querySelectorAll('.accordion__pic');
  if (contentPic) {
    btn.addEventListener('mouseover', () => {
      contentPics.forEach((evt) => {
        evt.classList.remove('show');
      });

      contentPic.classList.add('show');
    });
  }
};

const initAddAction = (accordion) => {
  const btn = accordion.children[0];
  const links = accordion.querySelectorAll('.main-people__link-item')
  if (links.length) {
    links.forEach((el) => {
      el.addEventListener('mouseover', () => {
        links.forEach((evt) => {
          evt.classList.remove('show');
        });

        el.classList.add('show');
      });

      el.addEventListener('mouseleave', () => {
        links.forEach((evt) => {
          evt.classList.remove('show');
        });
      });
    });
  }
};

const initHoverPic = () => {
  const accordions = document.querySelectorAll('.accordion');
  if (accordions.length) {
    accordions.forEach((accordion) => initAccordionAction(accordion));
    accordions.forEach((accordion) => initAddAction(accordion));
  }
};

export {initHoverPic};
