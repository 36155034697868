import Translator from '../services/translator';
const objectModalMarkup = ({logo, logoFormat, name, post, company, text, original}) => {
  return (
    `<div class="modal-review__head review-block">
      <div class="modal-review__avatar">
        <span class="modal-review__initials review-initials"></span>
        <img class="modal-review__avatar-image review-avatar" src="${logo}.${logoFormat}" width="140" height="140" srcset="${logo}@2x.${logoFormat} 2x" alt="Фото клиента ${name}">
      </div>
      <span class="modal-review__title review-name">${name}</span>
      <div class="modal-review__about">
        <span>${post}</span>
        <span>${company}</span>
      </div>
    </div>

    <div class="modal-review__main tab">
      <div class="modal-review__buttons tab__controls">
        <button class="modal-review__button tab__control btn btn-full" type="button" name="button">${Translator.trans('main.full_text')}</button>
        <button class="modal-review__button tab__control btn btn-original" type="button" name="button">${Translator.trans('main.original')}</button>
      </div>
      <div class="modal-review__items tab__items">
        <div class="modal-review__item tab__item modal-review__text tab-full">
          <p>${text}</p>
        </div>
        <div class="modal-review__item tab__item modal-review__document tab-original">
          <img src="${original}.jpg" width="696" height="983" srcset="${original}@2x.jpg 2x" alt="Оригинал документа">
        </div>
      </div>
    </div>
  `);
};

const objectModalTemplateReviews = (res, block) => {
  block.insertAdjacentHTML('afterbegin', objectModalMarkup(res));
};

export {objectModalTemplateReviews};
