const videoWraps = document.querySelectorAll('.video');

const initVideoPlayBtn = () => {

  if (videoWraps.length) {
    videoWraps.forEach((videoWrap) => {
      const video = videoWrap.querySelector('.video__container');
      const circlePlayButton = videoWrap.querySelector('.video__play-btn');
      const playBtn = videoWrap.querySelector('.video__play-btn-wrap');
      const mute = videoWrap.querySelector('.video__mute');
      const fullscreen = videoWrap.querySelector('.video__fullscreen');
      video.controls = false;

      if (videoWrap.dataset.volume) {
        video.volume = +videoWrap.dataset.volume;
      }

      const fullScreenEnabled = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);

      if (!fullScreenEnabled) {
        fullscreen.style.display = 'none';
        mute.style.display = 'none';
        circlePlayButton.classList.add('native-fullscreen');
      }

      const isFullScreen = () => {
        return !!(document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
      }

      const handleFullscreen = () => {
        if (isFullScreen()) {
           if (document.exitFullscreen) document.exitFullscreen();
           else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
           else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
           else if (document.msExitFullscreen) document.msExitFullscreen();
           setFullscreenData(false);
        }
        else {
           if (videoWrap.requestFullscreen) videoWrap.requestFullscreen();
           else if (videoWrap.mozRequestFullScreen) videoWrap.mozRequestFullScreen();
           else if (videoWrap.webkitRequestFullScreen) videoWrap.webkitRequestFullScreen();
           else if (videoWrap.msRequestFullscreen) videoWrap.msRequestFullscreen();
           setFullscreenData(true);
        }
     }

      const setFullscreenData = (state) => {
        //videoContainer.setAttribute('data-fullscreen', !!state);
        videoWrap.dataset.fullscreen = !!state;
      }

      fullscreen.addEventListener('click', (e) => {
        handleFullscreen();
        fullscreen.blur();
      });

      document.addEventListener('fullscreenchange', function(e) {
        setFullscreenData(!!(document.fullscreen || document.fullscreenElement));
      });
      document.addEventListener('webkitfullscreenchange', function() {
        setFullscreenData(!!document.webkitIsFullScreen);
      });
      document.addEventListener('mozfullscreenchange', function() {
        setFullscreenData(!!document.mozFullScreen);
      });
      document.addEventListener('msfullscreenchange', function() {
        setFullscreenData(!!document.msFullscreenElement);
      });

      const progressBar = videoWrap.querySelector('.video__progress-bar');

      if (progressBar) {
        const updateProgressBar = () => {
          const progressBar = videoWrap.querySelector('.video__progress-bar');
          let percentage = Math.floor((100 / video.duration) * video.currentTime);

          if (isNaN(percentage)) {
            percentage = 0;
         }
          progressBar.value = percentage;
        }

        video.addEventListener('timeupdate', updateProgressBar, false);


        progressBar.addEventListener('click', function(e) {
          let pos = (e.pageX  - (this.offsetLeft + this.offsetParent.offsetLeft)) / this.offsetWidth;
          video.currentTime = pos * video.duration;
       });
      }

      const playBtnHandler = () => {
        if (circlePlayButton) {
          if (video.ended) {
            circlePlayButton.dataset.state = 'play';
            video.play();
          } else {
            if (video.paused) {
              video.play();
              circlePlayButton.dataset.state = 'play';

            } else {
              video.pause();
              circlePlayButton.dataset.state = 'pause';
              circlePlayButton.blur();
            }
          }
        }
      };

      const checkInitialMute = () => {
        if (mute.dataset.state === 'mute') {
          video.muted = true
        } else {
          video.muted = false
        }

        if (videoWrap.dataset.volume) {
          video.volume = +videoWrap.dataset.volume;

          if (video.volume === 0) {
            mute.style.display = "none";
          }
        }
      }

      const changeMuteBtnState = () => {
        mute.setAttribute('data-state', video.muted ? 'unmute' : 'mute')
      }

      const showBtn = () => {
        circlePlayButton.style.opacity = 1;
      }

      const hiddenBtn = () => {
        circlePlayButton.style.opacity = 0;
      }

      playBtn.addEventListener('click', playBtnHandler);

      video.addEventListener('playing', () => {
        circlePlayButton.style.opacity = 0;
        playBtn.addEventListener('mouseover', showBtn)
        playBtn.addEventListener('mouseout', hiddenBtn)

      });
      video.addEventListener('pause', () => {
        circlePlayButton.style.opacity = 1;
        playBtn.removeEventListener('mouseout', hiddenBtn)
      });

      video.addEventListener("ended", () => {
        circlePlayButton.style.opacity = 1;
        circlePlayButton.dataset.state = 'ended';
        playBtn.removeEventListener('mouseout', hiddenBtn)
      })

      if (mute) {
        checkInitialMute();
        mute.addEventListener('click', (e) => {
          changeMuteBtnState();
          video.muted = !video.muted;
          mute.blur();
       });
      }

    });
  }
};
export {initVideoPlayBtn};
