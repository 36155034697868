import loadData from './load-data';
import loadReview from './load-review';
import initObjectsState from '../state/init-objects-state';
const objectsBlock = document.querySelector('.partners__list');
const objectsBlockReview = document.querySelector('.reviews__list');
const objectsBlockReviewMain = document.querySelector('.reviews-list');

const onObjectsGetResponse = (err, res) => {
  initObjectsState(err, res);
};

const initLoading = () => {
  if (objectsBlock) {
    let endpoint = objectsBlock.dataset.endpoint;
    loadData.loadObjects(endpoint, onObjectsGetResponse);
  }

  if (objectsBlockReview) {
    let endpoint = objectsBlockReview.dataset.endpoint;
    loadReview.loadObjects(endpoint, onObjectsGetResponse);
  }

  if (objectsBlockReviewMain) {
    let endpoint = objectsBlockReviewMain.dataset.endpoint;
    loadReview.loadObjects(endpoint, onObjectsGetResponse);
  }
};

export default initLoading;
