const filters = document.querySelectorAll('.clients-filter__tag');
const accordionBtns = document.querySelectorAll('.clients-filter__btn');

const removeControlActiveClass = (arr, cssClass) => {
  arr.forEach((el) => {
    el.classList.remove(cssClass);
  });
};

const removeItemActiveClass = (arr) => {
  arr.forEach((el) => {
    el.classList.add('no-opacity');
    setTimeout(() => {
      el.classList.add('hide');
    }, 300);
  });
};

const makeItemActive = (group, filter) => {
  if (filter) {
    group.forEach((item) => {
      if (item.dataset.filter === filter) {
        setTimeout(() => {
          item.classList.remove('no-opacity');
        }, 300);
        setTimeout(() => {
          item.classList.remove('hide');
        }, 300);
      }
    });
  } else {
    setTimeout(() => {
      group.classList.remove('no-opacity');
    }, 300);
    setTimeout(() => {
      group.classList.remove('hide');

    }, 300);
  }

};

const findFilter = (filter, cssClass) => {
  let element;

  if (filter.classList.contains(cssClass)) {
    element = filter;
  }

  return element;
};

const initFilterAction = (controls) => {
  const groupItems = document.querySelectorAll('.clients-content__item');
  const groupByAlphabet = document.querySelector('.content-all');

  controls.forEach((control) => {
    control.addEventListener('click', (e) => {
      e.preventDefault();
      removeItemActiveClass(groupItems);

      removeControlActiveClass(accordionBtns, 'tagged');
      const btnfilter = control.dataset.filter;

      // Закрывает меню при клике по фильтру
      // removeControlActiveClass(accordionBtns, 'active');

      const filterItem = control.closest('.clients-filter__item');
      const controlBtn = filterItem.querySelector('.clients-filter__btn');
      const controlAside = filterItem.querySelector('.clients-filter__aside');

      controlBtn.classList.add('tagged');

      // Закрывает меню при клике по фильтру
      // controlAside.classList.remove('active');
      // controlAside.removeAttribute('style');

      removeControlActiveClass(controls, 'clients-filter__tag--active');
      control.classList.add('clients-filter__tag--active');

      if (control.classList.contains('filter-all') && groupByAlphabet) {
        makeItemActive(groupByAlphabet);
      } else {
        makeItemActive(groupItems, btnfilter);
      }
    });
  });
};

const initfilters = () => {
  if (filters.length) {
    initFilterAction(filters);
  }
};

export {initfilters};
