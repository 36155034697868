const contentSlider = document.querySelectorAll('.slider--content');
const breakpointMd = window.matchMedia('(min-width:1024px)');
const breakpointSm = window.matchMedia('(min-width:768px)');
let mainSwiper;
let thumbsSwiper;

const getDirection = () => {
  let windowWidth = window.innerWidth;
  var direction = windowWidth <= 1023 ? 'horizontal' : 'vertical';
  return direction;
}

const pauseVideo = (video) => {
  const nextEl = video.nextElementSibling;
  video.pause();

  if (nextEl.firstElementChild.classList.contains('video__play-btn')) {
    nextEl.firstElementChild.dataset.state = 'pause';
  }
}

const initContentSliders = () => {
  if (contentSlider.length > 0) {
    contentSlider.forEach(function (e) {
      let mainSliders = e.querySelectorAll('.slider__main');

      thumbsSwiper = new Swiper('.slider__thumbs', {
        direction: getDirection(),
        spaceBetween: 20,
        slidesPerView: 3.3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        updateOnWindowResize: true,
      });

      if (mainSliders.length > 0) {
        mainSliders.forEach(function (evt) {
          let mainNavigation = evt.nextElementSibling;
          mainSwiper = new Swiper(evt, {
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
              nextEl: '.slider__btn--next',
              prevEl: '.slider__btn--prev',
            },
            pagination: {
              el: '.swiper-pagination',
              type: 'fraction',
            },
            thumbs: {
              swiper: thumbsSwiper
            },
          });

          mainSwiper.on('transitionEnd', () => {
            const activeVideo = document.querySelector('.swiper-slide-active video');

            if (activeVideo) {
              pauseVideo(activeVideo);
            }
          });
        });
      }
    });
  }
}

const initContentSlider = () => {
  const breakpointChecker = () => {
    if (mainSwiper && thumbsSwiper) {
      mainSwiper.destroy(true, true);
      thumbsSwiper.destroy(true, true);
      initContentSliders();
    } else {
      initContentSliders();
    }
  };

  breakpointMd.addListener(breakpointChecker);
  breakpointSm.addListener(breakpointChecker);
  breakpointChecker();
};

export {initContentSlider};
