import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/init-modals';
import initLoading from './services/init-loading';
import {initAccordion} from './modules/init-accordion';
import {initOpenSubmenu} from './modules/init-open-submenu';
import {initMenuActions} from './modules/init-menu-actions';
import {initMap} from "./modules/init-gmap";
import {initYaMap} from './modules/init-ya-map';
import {initContentSlider} from './modules/init-content-slider';
import {initTabs} from './modules/tabs';
import {initSearchAction} from './modules/init-search-action';
import {initBurgerAction} from './modules/init-burger-action';
import {initFormValidate} from './modules/init-validate';
import {initMissionSlider} from './modules/init-mission-slider';
import {initFeedbackSlider} from './modules/init-feedback-slider';
import {initAvatar} from './modules/init-avatar';
import {initInputWrappers} from './modules/init-custom-input';
import {initReviewTabs} from './modules/init-review-tabs';
import {initScrollUp} from './modules/init-scroll';
import {initProjectSlider} from './modules/init-project-slider';
import {initHoverPic} from './modules/init-hover-pic';
import {initHeroAction} from './modules/init-hero-action';
import {initInputSelect} from './modules/init-custom-select';
import {connectInputSelectTab} from './modules/custom-input-tabs';
import {fixHeader} from './modules/init-fix-header';
import {observeFixed} from './modules/observe-fixed';
import {initfilters} from './modules/init-filters';
import {initPhoneMask} from './modules/init-phone-mask';
import {initProgressBar, initProgressBarAction} from './modules/init-progress-bar';
import {initAside, animateAside} from './modules/aside';
import {initVideoPlayBtn} from './modules/init-video-btn';
import {initAboutSlider} from './modules/init-about-slider';
import {initLang} from './modules/change-lang';
import './modules/custom-select';
import {initBlog} from "./modules/blog";
import {initFeedbackForm} from "./modules/feedback";

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

initModals();
initPhoneMask();
initYaMap();
initLoading();
//initMap();
initAccordion();
initOpenSubmenu();
initMenuActions();
initContentSlider();
initTabs();
initSearchAction();
initBurgerAction();
initInputWrappers();
initFormValidate();
initMissionSlider();
initAvatar();
initFeedbackSlider();
initReviewTabs();
initProjectSlider();
initScrollUp();
initHoverPic();
initHeroAction();
fixHeader();
observeFixed();
initInputSelect();
connectInputSelectTab();
initfilters();
initProgressBar();
initProgressBarAction();
initAside();
animateAside();
initVideoPlayBtn();
initAboutSlider();
initLang();
initBlog();
initFeedbackForm();
