const header = document.querySelector('.header');
const breakpointLg = window.matchMedia('(max-width: 1023px)');
const headerMenu = document.querySelector('.header__wrapper');
if (header) {
  const brBlock = header.querySelector('.main-nav__breadcrumbs-block');
}

let arr = [];
let item;

const clickSubmenuDesktop = (submenu, bottomBlock, e) => {

  if (header.classList.contains('open')) {
    header.classList.remove('header--black');
    let elParent = e.target.closest('.js-submenu');
    let submenuContent = elParent.querySelector('.js-submenu-content');
    resetMenu();
    elParent.classList.add('open');
    elParent.classList.add('clicked');
    submenuContent.classList.add('open');
    submenuContent.classList.add('clicked');
    elParent.closest('.js-submenu-content').classList.add('clicked');
    elParent.closest('.js-submenu-content').classList.add('hover');
    elParent.closest('.js-menu').classList.add('clicked');
    if (e.target.classList.contains('accordion__btn')) {
      e.target.closest('.js-submenu-content').classList.add('hover');
      e.target.classList.add('active');
      const content = e.target.closest('.accordion').querySelector('.accordion__content');
      content.classList.add('active');
      content.style.maxHeight = content.scrollHeight + 'px';
    }

    if (submenu.closest('.js-menu').classList.contains('show-bottom') && !breakpointLg.matches) {
      bottomBlock.classList.add('show');
    } else {
      bottomBlock.classList.remove('show');
    }
  }
};

const clickMenuDesktop = (menu, bottomBlock, e) => {

  header.classList.remove('header--black');
  let elParentSub = e.target.closest('.js-submenu');
  let elParent = e.target.closest('.js-menu');
  if (!elParentSub) {
    e.preventDefault;
    let submenuContent1 = elParent.querySelector('.js-submenu-content');
    resetMenu();
    elParent.classList.add('open');
    submenuContent1.classList.add('open');

    if (elParent.classList.contains('show-bottom') && !breakpointLg.matches) {
      bottomBlock.classList.add('show');
    } else {
      bottomBlock.classList.remove('show');
    }
  }
};

const clickSubMenuMobile = (e) => {
    let elParent = e.target.closest('.js-submenu');
    let submenuContent = elParent.querySelector('.js-submenu-content');
    elParent.classList.add('open');
    submenuContent.classList.add('open');
    if (submenuContent.querySelector('.accordion__content.active')) {
      item = submenuContent.querySelector('.accordion__btn.active').textContent;
      addBr(item);
    } else {
      item = elParent.childNodes[1].textContent;
      addBr(item);
    }
    window.disableBodyScroll(headerMenu);
}

const clickMenuMobile = (menu, e) => {

    let elParentSub = e.target.closest('.js-submenu');
    let elParent = e.target.closest('.js-menu');
    if (!elParentSub) {
      let submenuContent = elParent.querySelector('.js-submenu-content');
      elParent.classList.add('open');
      submenuContent.classList.add('open');
      item = elParent.childNodes[1].textContent;
      addBr(item);

      window.disableBodyScroll(headerMenu);
    }
}

const addBr = () => {
  var input = document.createElement('span');
  input.textContent = item;
  var element = header.querySelector('.main-nav__breadcrumbs-block');
  element.appendChild(input);
  arr.push(item);
}

const removeBr = () => {
  let asd = brBlock.querySelectorAll('span');
  asd[asd.length-1].remove();
}

const clickBackBtn = () => {
  const openSub = header.querySelectorAll('.js-submenu.open');
  const openMenu = header.querySelectorAll('.js-menu.open');
  const openContent = header.querySelectorAll('.js-submenu-content.open');
  const openAccBtn = header.querySelector('.accordion__btn.active');
  const openAcc = header.querySelector('.accordion__content.active');
  if (openSub.length > 0) {

    if (openAccBtn && openAcc) {
      resetAcc();
      removeBr();

    } else {
      openSub[(openSub.length - 1)].classList.remove('open');
      openContent[(openSub.length)].classList.remove('open');
      removeBr();
    }

  } else if ((openMenu.length - 1) === 0) {
    header.classList.remove('open');
    openMenu.forEach((menu) => {
      menu.classList.remove('open');
    });
    removeBr();
  }

  arr.length = arr.length - 1;
}

const resetAcc = () => {
  const activeEls = header.querySelectorAll('.accordion');
  const openAccBtn = header.querySelector('.accordion__btn.active');
  const openAcc = header.querySelector('.accordion__content.active');

  if (openAccBtn && openAcc) {
    activeEls.forEach((activeEl)=> {
      activeEl.children[0].classList.remove('active');
      activeEl.children[1].classList.remove('active');
      activeEl.children[1].removeAttribute('style');
    });
  }
}

const resetMenu = () => {
  const openEls = header.querySelectorAll('.open');
  const clickedEls = header.querySelectorAll('.clicked');
  const hoverEls = header.querySelectorAll('.hover');
  openEls.forEach((openEl)=> {
    openEl.classList.remove('open');
  });

  clickedEls.forEach((clickedEl)=> {
    clickedEl.classList.remove('clicked');
  });

  hoverEls.forEach((hoverEl)=> {
    hoverEl.classList.remove('hover');
  });

  resetAcc();
}

const initOpenSubmenu = () => {
  if (header) {

    const submenues = header.querySelectorAll('.js-submenu');
    const menues = header.querySelectorAll('.js-menu');
    const headerClose = header.querySelector('.header__toggle');

    const bottomBlock = header.querySelector('.main-nav__bottom');
    const backBtn = document.querySelector('.main-nav__breadcrumbs-back');

    submenues.forEach((submenu)=> {
      submenu.addEventListener('click', (e) => {
        if (!breakpointLg.matches) {
          clickSubmenuDesktop(submenu, bottomBlock, e);
        } else {
          clickSubMenuMobile(e);
        }
      });
    });

    menues.forEach((menu)=> {
      menu.addEventListener('click', (e) => {
        if (!breakpointLg.matches) {
          let links = menu.querySelectorAll('a');
          links.forEach((link) => {
            link.addEventListener('mouseover', () => {
              header.classList.remove('header--black');
            });
          });
          clickMenuDesktop(menu, bottomBlock, e);
        } else {
          clickMenuMobile(menu, e);
        }
      });
    });

    if (!backBtn) {
      return;
    }

    backBtn.addEventListener('click', () => {
      clickBackBtn();
    });

    headerClose.addEventListener('click', () => {
      arr = [];
      let asd = brBlock.querySelectorAll('span');
      for (let i = 1; i < asd.length; i ++) {
        asd[i].remove();
      }
    })

    breakpointLg.addListener(() => {
      header.classList.remove('header--black');
      resetMenu();
    });
  }
}

export {initOpenSubmenu};
