const linksFull = document.querySelectorAll('.review-btn-full');
const linksOriginal = document.querySelectorAll('.review-btn-original');

const setTabActive = (btn, tab) => {
  btn.classList.add('active');
  tab.classList.remove('hide', 'no-opacity');
}

const setTabInactive = (btn, tab) => {
  btn.classList.remove('active');
  tab.classList.add('hide', 'no-opacity');
}

const initReviewTabs = () => {

  let objectModalReview = document.querySelector('.modal-review__main');
  if (objectModalReview) {
    const btnFull = document.querySelector('.btn-full');
    const btnOriginal = document.querySelector('.btn-original');
    const tabFull = document.querySelector('.tab-full');
    const tabOriginal = document.querySelector('.tab-original');

    if (linksFull) {
      linksFull.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.target.blur();
          setTabInactive(btnOriginal, tabOriginal);
          setTabActive(btnFull, tabFull);
        });
      });
    }

    if (linksOriginal) {
      linksOriginal.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.target.blur();
          setTabInactive(btnFull, tabFull);
          setTabActive(btnOriginal, tabOriginal);
        });
      });
    }
  }

}

export {initReviewTabs, setTabActive, setTabInactive};
