import {objectModalTemplate} from '../components/partners-modal-template'
import {objectModalTemplateReviews} from '../components/reviews-modal-template'
import {setupModal} from '../utils/modal';
import {initReviewTabs, setTabActive, setTabInactive} from '../modules/init-review-tabs';
import {initTabs} from '../modules/tabs';
import {onImageLoad} from '../modules/init-avatar';

const objectModal = document.querySelector('.modal--partner');
const objectModalContent = document.querySelector('.modal-partner');

const objectModalReview = document.querySelector('.modal--review');
const objectModalContentReview = document.querySelector('.modal-review');


const createModalObject = (objects) => {
  const newArr = objects;
  return newArr.reduce((acc, el) => {
    acc[el.id] = el;
    console.log(acc);
    return acc;
  }, {});
};

const closeCb = () => {
  setTimeout(() => {
    if (objectModalContent) {
      objectModalContent.innerHTML = '';
    }
    if (objectModalContentReview) {
      objectModalContentReview.innerHTML = '';
    }
  }, 100);
};

const linkClickHandler = (el, modalObject) => {
  const dataId = el.dataset.id;
  const currentObject = modalObject[dataId];
  if (el.dataset.modal === "partner") {
    objectModalTemplate(currentObject, objectModalContent);
  }
  if (el.dataset.modal === "review") {
    objectModalTemplateReviews(currentObject, objectModalContentReview);
  }
};


const initObjectsState = (err, res) => {
  if (err || !res) {
    return;
  }

  const modalObject = createModalObject(res);

  const objectsLinks = document.querySelectorAll('[data-modal="partner"]');
  const objectsLinksReview = document.querySelectorAll('[data-modal="review"]');

  if (objectModal && objectsLinks.length) {
    setupModal(objectModal, closeCb, objectsLinks, false, false);

    objectsLinks.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        el.blur();
        linkClickHandler(el, modalObject);
      });
    });
  }


  if (objectModalReview && objectsLinksReview.length) {
    setupModal(objectModalReview, closeCb, objectsLinksReview, false, false);
    objectsLinksReview.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        el.blur();
        linkClickHandler(el, modalObject);
        const btnFull = document.querySelector('.btn-full');
        const btnOriginal = document.querySelector('.btn-original');
        const tabFull = document.querySelector('.tab-full');
        const tabOriginal = document.querySelector('.tab-original');
        const reviewCards = objectModalReview.querySelectorAll('.review-block');
        if (el.classList.contains('review-btn-full')) {
          setTabInactive(btnOriginal, tabOriginal);
          setTabActive(btnFull, tabFull);
        } else if (el.classList.contains('review-btn-original')) {
          setTabInactive(btnFull, tabFull);
          setTabActive(btnOriginal, tabOriginal);
        }
        initReviewTabs();
        initTabs();
        onImageLoad(reviewCards);
      });
    });

  }

};

export default initObjectsState;
