const aboutSlider = document.querySelectorAll('.about__slider');
const currentYear = document.querySelector('.about__year');
const breakpointMd = window.matchMedia('(min-width:1024px)');
const breakpointSm = window.matchMedia('(min-width:768px)');
let mainSwiper;
let thumbsSwiper;

const pauseVideo = (video) => {
  const nextEl = video.nextElementSibling;
  video.pause();

  if (nextEl.firstElementChild.classList.contains('video__play-btn')) {
    nextEl.firstElementChild.dataset.state = 'pause';
  }
}

const changeYear = () => {
  const activeYear = document.querySelector('.swiper-slide-thumb-active');
  if (activeYear && currentYear) {
    currentYear.textContent = activeYear.textContent;
  }
}

const initAboutSliders = () => {
  if (aboutSlider.length > 0) {
    aboutSlider.forEach(function (e) {
      let mainSliders = e.querySelectorAll('.about__main');

      thumbsSwiper = new Swiper('.about__thumbs', {
        spaceBetween: 10,
        slidesPerView: 1,
        freeMode: true,
        autoScrollOffset: 10,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        updateOnWindowResize: true,

        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 32
          },
          767: {
            slidesPerView: 1,
            spaceBetween: 37
          },
          1000: {
            slidesPerView: 1,
            spaceBetween: 41
          }
        }
      });

      if (mainSliders.length > 0) {
        mainSliders.forEach(function (evt) {
          let mainNavigation = evt.nextElementSibling;
          mainSwiper = new Swiper(evt, {
            slidesPerView: 1,
            spaceBetween: 0,
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            autoHeight: true,

            navigation: {
              nextEl: '.about__btn--next',
              prevEl: '.about__btn--prev',
            },
            thumbs: {
              swiper: thumbsSwiper
            },
          });

          mainSwiper.on('slideChange', () => {
            changeYear();
          });
        });
      }
    });
  }
}

const initAboutSlider = () => {
  const breakpointChecker = () => {
    if (mainSwiper && thumbsSwiper) {
      mainSwiper.destroy(true, true);
      thumbsSwiper.destroy(true, true);
      initAboutSliders();
    } else {
      initAboutSliders();
    }
  };

  breakpointMd.addListener(breakpointChecker);
  breakpointSm.addListener(breakpointChecker);
  breakpointChecker();
};

export {initAboutSlider};
