const scrollUpWrapper = document.querySelector('.scroll-up');
const pageWrapper = document.querySelector('.wrapper');
const screenCounter = 3;
// const breakpoint = window.matchMedia('(min-width:1024px)');


// eslint-disable-next-line no-undef
const moveTo = new MoveTo({
  duration: 800,
  easing: 'easeOutQuart',
});

const scrollToBlock = (block) => {

  setTimeout(() => {
    moveTo.move(block);
  }, 100);
}

const initScrollUpBtn = () => {
  const scrollUpBtn = scrollUpWrapper.querySelector('.scroll-up__btn');

  if (window.scrollY > window.innerHeight * (screenCounter - 1)) {
    setTimeout(() => {
      scrollUpBtn.classList.remove('no-opacity');
      scrollUpWrapper.classList.add('fixed');
    }, 300);

    scrollUpBtn.addEventListener('click', (e) => {
      e.preventDefault();
      scrollToBlock(pageWrapper);
    });
  } else {

    setTimeout(() => {
      scrollUpBtn.classList.add('no-opacity');
      scrollUpWrapper.classList.remove('fixed');
    }, 300);
  }
};

const initScrollUp = () => {
  if (scrollUpWrapper) {
    window.addEventListener('scroll', initScrollUpBtn);
  }
};

export {initScrollUp};
