const projectBlocks = document.querySelectorAll('.slider--project');

const setupMouseHover = (projectSwiper, projectSliderThumbs, e) => {

  var thumbs = projectSliderThumbs.querySelectorAll('.swiper-slide');
  for(let i = 0; i < thumbs.length; i++) {
    thumbs[i].addEventListener('mouseenter', () => {
      projectSwiper.slideTo(i);
      e.querySelector('.slider__progress').classList.remove('active');
      setTimeout(() => {
        e.querySelector('.slider__progress').classList.add('active');
      }, 10);
    });

  }
}

const setupActiveChange = (thumbsSwiper, projectSwiper, e) => {

  thumbsSwiper.on('activeIndexChange', () => {
    projectSwiper.slideTo(thumbsSwiper.activeIndex);
  });

  projectSwiper.on('activeIndexChange', () => {
    thumbsSwiper.slideTo(projectSwiper.activeIndex);
  });

  projectSwiper.on('slideChange', () => {
    e.querySelector('.slider__progress').classList.remove('active');
    setTimeout(() => {
      e.querySelector('.slider__progress').classList.add('active');
    }, 10);
  });
}

const setupProjectSlider = (e) => {
  let projectSlider = e.querySelector('.main-project__slider-main');
  let projectSliderThumbs = e.querySelector('.main-project__slider-thumbs');
  if (projectSlider && projectSliderThumbs) {

    let thumbsSwiper = new Swiper(projectSliderThumbs, {
      slidesPerView: 4,
      clickable: true,
      resistanceRatio: 0,

      breakpoints: {
        320: {
          slidesPerView: 1,
          direction: 'horizontal',
          resistanceRatio: 0.85,
          spaceBetween: 17,
        },

        768: {
          slidesPerView: 4,
          direction: 'vertical',
          resistanceRatio: 0,
          spaceBetween: 0,
        },
      },

      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });

    let projectSwiper = new Swiper(projectSlider, {
      effect: 'fade',
      speed: 100,

      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },

      fadeEffect: {
        crossFade: true
      },

      thumbs: {
        swiper: thumbsSwiper
      },
    });

    setupMouseHover(projectSwiper, projectSliderThumbs, e);
    setupActiveChange(thumbsSwiper, projectSwiper, e);

    window.addEventListener('load', function () {
      e.querySelector('.slider__progress').classList.add('active');
    });
  }
};

const initProjectSlider = () => {
  if (projectBlocks.length) {
    projectBlocks.forEach((e) => {

      setupProjectSlider(e);
      const projectSlider = e.querySelector('.main-project__slider-main');
      const swiper = projectSlider.swiper;
      const verticalCenter = window.innerHeight / 2;

      window.addEventListener('scroll', function () {
        const elBottom = e.getBoundingClientRect().bottom;
        const elTop = e.getBoundingClientRect().top;
        if (elTop < window.innerHeight && elBottom > 0) {
          swiper.autoplay.start();
          e.querySelector('.slider__progress').classList.add('active');
        } else {
          swiper.autoplay.stop();
          e.querySelector('.slider__progress').classList.remove('active');
        }
      })
    });
  }
};

export {initProjectSlider};
