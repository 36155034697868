import http from './http';
import config from '../config/api-config';

const loadReview = (function() {
  const url = config.url;
  return {
    loadObjects(endpoint, cb) {
      http.get(`${url}${endpoint}`, cb);
    },
  };
})();

export default loadReview;
