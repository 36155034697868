const breakpointLg = window.matchMedia('(min-width:1024px)');
const contentBlock = document.querySelector('.contacts__list');

const contentHeight = 660;
const padding = 0;

const aside = document.querySelector('.aside');
let content;

const animateAside = () => {
  if (!aside && !contentBlock) {
    return;
  }

  if (breakpointLg.matches) {
    const headerHeight = document.querySelector('.header').getBoundingClientRect().height;
    const offset = window.pageYOffset + headerHeight;

    const topFix = aside.getBoundingClientRect().top + window.pageYOffset - padding;
    const heightDifference = aside.getBoundingClientRect().height - contentHeight;
    const top = aside.getBoundingClientRect().top;
    const limitPosition = heightDifference + top - headerHeight - padding;
    aside.style.minHeight = contentHeight + 'px';

    if (offset > topFix) {
      if (limitPosition > 0) {
        content.setAttribute('style', 'position: fixed; top: ' + (headerHeight + padding) + 'px; width: ' + aside.clientWidth + 'px');
      } else {
        content.setAttribute('style', 'position: absolute; top: ' + (heightDifference) + 'px');
      }
    } else {
      content.removeAttribute('style');
    }
  } else {
    content.removeAttribute('style');
    aside.removeAttribute('style');
  }
};

const initAside = () => {
  if (!aside && !contentBlock) {
    return;
  }
  content = aside.querySelector('.aside__content');
  animateAside();
  window.addEventListener('resize', animateAside);
  window.addEventListener('scroll', animateAside);

  const callback = () => {
    animateAside();
  };

  const resizeObserver = new ResizeObserver(callback);
  resizeObserver.observe(aside);
};

export {initAside, animateAside};
