const initYaMap = () => {
  const breakpointLg = window.matchMedia('(max-width:1023px)');
  const mapBlock = document.querySelector('#map1');
  const mapInfoComtainer = document.querySelector('.contacts__form-wrap');
  const coordsContainers = document.querySelectorAll('*[data-coordinates]');

  const accBtns = document.querySelectorAll('.contacts__accordeon-btn');
  const officeBtns = document.querySelectorAll('.contacts__office');
  const inputSelect = document.querySelector('.custom-input__wrapper input');
  const dropdownCitys = document.querySelectorAll('.custom-input__select-item');
  let cityShown = "moscow";



  function init() {
    const cityLinks = mapInfoComtainer.querySelectorAll('*[data-city]');

    let cityCoordinates = {};

    coordsContainers.forEach((el) => {
      cityCoordinates[el.dataset.city] = el.dataset.coordinates.split(',');
    });


    if (!Object.keys(cityCoordinates).length) {
      cityCoordinates = {
        moscow: [55.779064, 37.586922],
        peterburg: [59.93798892, 30.32041551],
        kiev: [50.434964, 30.501388],
        minsk: [53.905477, 27.520662],
        almaty: [43.235016, 76.958796],
        astana: [51.15333, 71.429172],
        atyrau: [47.105223, 51.891335],
        buhares: [44.428533, 26.109363],
      };
    }

    const iconPath = mapBlock.dataset.iconPath || 'img/svg/pin.svg';

    const screens = {
      sm: 320,
      xl: 1024,
    };

    const iconSize = {
      sm: [29, 38],
      xl: [44, 56],
    };

    const iconOffset = {
      sm: [-15, -38],
      xl: [-22, -56],
    };

    const mapCenter = {
      sm: cityCoordinates.moscow,
      xl: cityCoordinates.moscow,
    };

    const mapZoom = {
      sm: 12,
      xl: 13.52,
    };

    function getScreenSize() {
      const documentWidth = document.documentElement.clientWidth;

      if (documentWidth < screens.xl) {
        return 'sm';
      }

      return 'xl';
    }

    const screenSize = getScreenSize();

    let map = new ymaps.Map(mapBlock, {
      center: mapCenter[screenSize],
      zoom: mapZoom[screenSize],
      controls: ['zoomControl'],
    });

    map.container.fitToViewport();
    map.behaviors.disable('drag');
    map.behaviors.disable('multiTouch');
    map.behaviors.disable('scrollZoom');
    map.events.add('click', function () {
      map.behaviors.enable('scrollZoom');
    });


    function createMarker(screenWidth, coordinates) {
      return new ymaps.Placemark(coordinates, {}, {
        hintContent: 'Office Solutions',
        iconLayout: 'default#image',
        iconImageHref: iconPath,
        iconImageSize: iconSize[screenWidth],
        iconImageOffset: iconOffset[screenWidth],
      });
    }

    let marker = createMarker(screenSize, cityCoordinates['moscow']);
    let marker2;
    let marker3;
    let marker4;
    let marker5;
    let marker6;
    let marker7;
    let marker8;

    if (screenSize === 'sm') {
      marker2 = createMarker(screenSize, cityCoordinates['peterburg']);
      map.geoObjects.add(marker2);
      marker3 = createMarker(screenSize, cityCoordinates['kiev']);
      map.geoObjects.add(marker3);
      marker4 = createMarker(screenSize, cityCoordinates['minsk']);
      map.geoObjects.add(marker4);
      marker5 = createMarker(screenSize, cityCoordinates['almaty']);
      map.geoObjects.add(marker5);
      marker6 = createMarker(screenSize, cityCoordinates['astana']);
      map.geoObjects.add(marker6);
      marker7 = createMarker(screenSize, cityCoordinates['atyrau']);
      map.geoObjects.add(marker7);
      marker8 = createMarker(screenSize, cityCoordinates['buhares']);
      map.geoObjects.add(marker8);
    }

    map.geoObjects.add(marker);

    const cityHandler = (evt) => {
      let cityChoice = evt.target.closest('*[data-city]').dataset.city;
      map.setCenter(cityCoordinates[cityChoice]);
      cityShown = cityChoice;

      if (marker) {
        map.geoObjects.remove(marker);
      }
      if (marker2) {
        map.geoObjects.remove(marker2);
      }
      if (marker3) {
        map.geoObjects.remove(marker3);
      }
      if (marker4) {
        map.geoObjects.remove(marker4);
      }
      if (marker5) {
        map.geoObjects.remove(marker5);
      }
      if (marker6) {
        map.geoObjects.remove(marker6);
      }
      if (marker7) {
        map.geoObjects.remove(marker7);
      }
      if (marker8) {
        map.geoObjects.remove(marker8);
      }
      marker = createMarker(screenSize, cityCoordinates[cityChoice]);
      map.geoObjects.add(marker);
    }

    if (breakpointLg.matches) {
      dropdownCitys.forEach((city) => {
        city.addEventListener('click', (evt) => {
          cityHandler(evt);
      })
    })

    } else {
      accBtns.forEach((accBtn) => {
        accBtn.addEventListener('click', (evt) => {
          const activeBtn = evt.target.closest('.active');
          if(!activeBtn) {
            return;
          } else {
            cityHandler(evt);
          }
          })
    })
  }

  officeBtns.forEach((officeBtn) => {
    officeBtn.addEventListener('click', (evt) => {
      cityHandler(evt);
  })
})


    map.events.add('sizechange', function (event) {
      const oldWidth = event.get('oldSize')[0];
      const newWidth = event.get('newSize')[0];
      const documentWidth = document.documentElement.clientWidth;

      function updateMarker() {
        if (marker2) {
          map.geoObjects.remove(marker2);
        }
        if (marker3) {
          map.geoObjects.remove(marker3);
        }
        map.geoObjects.remove(marker);
        marker = createMarker(getScreenSize(), cityCoordinates[cityShown]);
        map.geoObjects.add(marker);
      }

      if (oldWidth < screens.xl && newWidth >= screens.xl) {
        updateMarker();
      }

      if (oldWidth >= screens.xl && newWidth < screens.xl) {
        if (marker) {
          map.geoObjects.remove(marker);
        }
        if (marker2) {
          map.geoObjects.remove(marker2);
        }
        if (marker3) {
          map.geoObjects.remove(marker3);
        }

        marker = createMarker(getScreenSize(), cityCoordinates['moscow']);
        marker2 = createMarker(getScreenSize(), cityCoordinates['peterburg']);
        marker3 = createMarker(getScreenSize(), cityCoordinates['kiev']);
        map.geoObjects.add(marker);
        map.geoObjects.add(marker2);
        map.geoObjects.add(marker3);
      }

      if (newWidth >= screens.xl) {
        map.setCenter(cityCoordinates[activeTab.dataset.city], mapZoom.xl);
      }

      if (newWidth < screens.xl) {
        if (!(documentWidth >= 1024)) {
          map.setCenter(mapCenter.sm, mapZoom.sm);
        }
      }
    });
  }

  if (mapBlock) {
    ymaps.ready(init);
  }

};

export {initYaMap};
