const getInitials = (string) => {
  let names = string.split(' ');
  let initials = names[0].charAt(0).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].charAt(0).toUpperCase();
  }
  return initials;
};

const onImageLoad = (cards) => {

  cards.forEach((card) => {
    let avatar = card.querySelector('.review-avatar');
    let initials = card.querySelector('.review-initials');
    let name = card.querySelector('.review-name').textContent;

    if (avatar.complete && avatar.naturalHeight !== 0) {
      initials.remove();
    } else {
      initials.textContent = getInitials(name);
    }
    return card
  });
}

const initAvatar = () => {
  const reviewCards = document.querySelectorAll('.review-block');

  window.addEventListener("load", event => {
    onImageLoad(reviewCards);
  });


}

export {initAvatar, onImageLoad};
